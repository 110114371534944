<template>
  <vs-popup :id="job.jobTitle" class="overflow-visible" :title="jobFeedbackTitle" :active.sync="isActive">
    <div>
      <div>
        <p v-if="mode === 'filled'">Are you sure you want to list this position as filled?</p>
        <p v-if="mode === 'archive'">Are you sure you want to archive this job?</p>
        <br />
        <p
          >Doing so will remove it from the Jobs Board and guides will be unable to apply for the position. The job will be moved into the
          Archived Jobs tab, where you can reactive it again at a later date. Activating the job again will cost you a relevant Job Post
          Credit.
        </p>
        <br />
        <p>You can permanently delete a job once it has been archived.</p>
        <br />
      </div>

      <!-- <p class="mb-2 font-semibold">Did you find a suitable candidate for this role?</p>
      <div class="flex flex-col space-y-2 mb-5">
        <vs-radio v-model="candidateFound" vs-name="candidateFound" :vs-value="true">Yes</vs-radio>
        <vs-radio v-model="candidateFound" vs-name="noCandidateFound" :vs-value="false">No</vs-radio>
      </div> -->
      <!-- <div v-if="candidateFound !== null"> -->
      <!-- :label="candidateFound ? 'How did you find the candidate?' : 'Why did you not find a candidate?'" -->
      <p class="mb-5 sm:mb-3"
        ><strong>To help us improve the quality of our service, please provide details on how this position was filled. </strong></p
      >
      <vs-select v-model="archiveJobReason" class="w-full sm:mb-0 mb-5 overflow-hidden" placeholder="Select a reason">
        <vs-select-item v-for="(item, index) in feedbackOptions" :key="index" :value="item" :text="item" />
      </vs-select>
      <!-- </div> -->
      <div class="mt-3 sm:mt-5">
        <!-- <vs-select
          v-if="getJobApplicants.length > 0 && archiveJobReason === 'Filled this position through the Recruit a Guide platform'"
          v-model="selectedApplicant"
          class="w-full sm:mb-0 mb-5 overflow-hidden"
          label="Which applicant did you select?"
          placeholder="- Select an applicant -"
        >
          <vs-select-item v-for="(item, index) in getJobApplicants" :key="index" :value="item.value" :text="item.text" />
        </vs-select> -->
        <vs-textarea
          v-if="archiveJobReason === 'Other'"
          v-model="otherExplanation"
          class="w-full"
          placeholder="Why did you select other?"
          :counter="250"
        ></vs-textarea>
      </div>
    </div>
    <!-- <vs-alert color="warning">
      <p
        >Doing so will remove it from the Jobs Board and guides will be unable to apply for the position. The job will be moved into the
        Archived Jobs tab, where you can reactive it again at a later date. Activating the job again will cost you a relevant Job Post
        Credit.
      </p>
      <br />
      <p>You can permanently delete a job once it has been archived.</p>
    </vs-alert> -->
    <div class="vx-row mr-0 sm:mr-5 ml-0 mt-8">
      <vs-button icon="archive" color="primary" icon-before class="vx-col w-full sm:w-1/3 relative" @click="submit()"
        >Submit & Archive</vs-button
      >
    </div>
  </vs-popup>
</template>

<script>
import { doc, setDoc } from 'firebase/firestore';
import { db } from '@/firebase/firebaseConfig';
// import { formatStoreData } from '@/helpers/format-store-data';
export default {
  props: {
    active: { type: Boolean },
    mode: { required: true, type: String },
    job: { type: Object, default: () => {} },
  },
  data() {
    return {
      jobFeedbackActive: false,
      candidateFound: null,
      archiveJobReason: '',
      selectedApplicant: null,
      otherExplanation: '',
    };
  },

  computed: {
    feedbackOptions() {
      const filters = this.$store.getters['filtersModule/getFilters'];
      // return this.candidateFound ? filters.candidate_found['candidate_found'] : filters.candidate_not_found['candidate_not_found'];
      return filters.archive_options['archive_options'];
    },
    jobFeedbackTitle() {
      if (this.mode === 'filled') return 'Has this position been filled?';
      return 'You are about to archive this job';
    },

    // getJobApplicants() {
    //   const jobApps = formatStoreData(this.$store.state.applicationsModule.data).filter((app) => app.jobId === this.job.jobId);
    //   return jobApps.map((app) => {
    //     return {
    //       text: app.userData.userName,
    //       value: {
    //         userName: app.userData.userName,
    //         userId: app.userId,
    //       },
    //     };
    //   });
    // },
    isActive: {
      get() {
        return this.active;
      },
      set(val) {
        this.clearForm();
        this.$emit('close', val);
      },
    },
  },
  // watch: {
  //   candidateFound() {
  //     this.archiveJobReason = '';
  //     this.selectedApplicant = null;
  //     this.otherExplanation = '';
  //   },
  // },
  methods: {
    clearForm() {
      this.archiveJobReason = '';
      // this.candidateFound = null;
      this.selectedApplicant = null;
      this.otherExplanation = '';
    },
    submit() {
      this.$emit('submit', {
        archiveReason: this.archiveJobReason,
        // candidateFound: this.candidateFound,
        otherExplanation: this.otherExplanation,
      });
      this.saveFeedback();
      this.clearForm();
    },
    async saveFeedback() {
      const feedback = {
        // userData: this.selectedApplicant ?? {},
        jobData: {
          jobTitle: this.job.jobTitle,
          jobId: this.job.jobId,
        },
        submittedAt: new Date(),
        // candidateFound: this.candidateFound,
        feedbackOption: this.archiveJobReason,
        otherExplanation: this.otherExplanation,
        archiveType: this.mode,
      };
      // if (this.selectedApplicant) {
      //   const userRef = doc(db, 'users', this.selectedApplicant.userId);
      //   updateDoc(userRef, {
      //     positionsFilled: arrayUnion({
      //       jobTitle: this.job.jobTitle,
      //       jobId: this.job.jobId,
      //       jobArchivedAt: new Date(),
      //     }),
      //   });
      // }
      return await setDoc(doc(db, 'feedback', 'employer_feedback', 'job_archived', this.job.jobId), feedback);
    },
  },
};
</script>
