<template>
  <div @keydown.esc="addNewJob = false">
    <JobsTour />
    <transition :name="routerTransition" mode="out-in">
      <div v-if="!addNewJob && jobs && jobs.length > 0">
        <div class="lg:w-auto w-full mb-4 flex justify-end">
          <vs-button id="post-job-2" class="pr-6 pl-4 w-full sm:w-auto" icon="add" @click="postJob()">Create a Job Post</vs-button>
        </div>

        <vx-card v-for="jobData in jobs" :key="jobData.jobId" class="px-2 relative py-3 sm:px-5 border-solid border-2 border-gray-200 mb-5">
          <div class="flex items-center justify-between">
            <p class="font-medium text-rag-green truncate text-xl">
              {{ jobData.jobTitle }}
            </p>
            <div class="ml-2 flex-shrink-0 flex">
              <div
                class="absolute sm:relative flex items-center bottom-6 right-0 sm:mb-1 sm:mr-3 sm:inset-auto px-6 sm:px-0 w-full sm:w-auto"
              >
                <vs-button type="border" class="py-2 px-3 text-xs w-full sm:w-auto" @click="inviteGuide(jobData)">Invite guides</vs-button>
              </div>
              <div class="flex items-center">
                <vs-chip class="mr-1" :color="jobData.jobDeactivated ? 'rgb(255,186,0)' : 'rgb(173, 202, 99)'">
                  {{ jobData.jobDeactivated ? 'Deactivated' : 'Active' }}
                </vs-chip>
              </div>

              <div>
                <vs-dropdown class="cursor-pointer" :vs-trigger-click="true">
                  <vs-icon size="medium" icon="more_vert"></vs-icon>
                  <vs-dropdown-menu>
                    <vs-dropdown-item>
                      <div class="flex items-center">
                        <vs-icon class="mr-3" icon="edit"></vs-icon>
                        <p @click="editJob(jobData)">Edit job</p>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <div class="flex items-center">
                        <vs-icon class="mr-3" icon="archive"></vs-icon>
                        <p @click="initArchive(jobData, 'archive')">Archive job</p>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item v-if="!jobData.jobDeactivated || jobData.jobDeactivated === false">
                      <div class="flex items-center">
                        <vs-icon class="mr-3" icon="task_alt"></vs-icon>
                        <p @click="initArchive(jobData, 'filled')">Job filled</p>
                      </div>
                    </vs-dropdown-item>
                    <vs-dropdown-item v-if="jobData.jobDeactivated === true" :disabled="jobData.submittedForReview ? true : false">
                      <div class="flex items-center">
                        <vs-icon class="mr-3" icon="task_alt"></vs-icon>
                        <p v-if="!jobData.submittedForReview" @click="initSubmitForReview(jobData)">Submit for review</p>
                        <p v-else>Submitted for review</p>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <p class="mt-2 flex text-base items-center text-gray-500 sm:mt-0">
                <feather-icon icon="MapPinIcon" class="text-grey mr-2"></feather-icon>
                {{ jobData.location }}
              </p>
              <p class="mt-2 flex text-base items-center text-gray-500 sm:mt-0 sm:ml-6">
                <feather-icon icon="UserIcon" class="text-grey mr-2"></feather-icon>
                {{ jobData.jobType }}
              </p>
            </div>
            <div class="sm:mt-0 mt-2 flex flex-col sm:flex-row">
              <div class="flex items-center mr-6">
                <div v-if="jobData.uniqueViews && jobData.uniqueViews.length > 0" class="flex items-center">
                  <feather-icon icon="EyeIcon" class="text-grey mr-2"></feather-icon>
                  <span class="text-gray-500"
                    >{{ jobData.uniqueViews.length }} {{ jobData.uniqueViews.length === 1 ? 'view' : 'views' }}</span
                  >
                </div>
              </div>
              <p class="mt-2 flex text-base items-center text-gray-500 sm:mt-0 sm:mr-3 mb-12 sm:mb-0">
                <feather-icon icon="CalendarIcon" class="text-grey mr-2"></feather-icon>
                Expires on
                <time :datetime="jobData.expire_at">&nbsp;{{ jobData.expire_at | formatDate }}</time>
              </p>
            </div>
          </div>
        </vx-card>
      </div>

      <vs-card
        v-if="(!addNewJob && !jobs) || (!addNewJob && jobs.length === 0)"
        class="px-12 py-24 sm:px-24 sm:py-32 rounded-lg bg-white h-10 border-solid border-2 border-grey-light min-w-full flex-col flex items-center justify-center"
      >
        <div>
          <p class="text-center font-bold text-lg"> No Active Jobs </p>
          <p class="mt-1 text-center text-grey text-base"> Please create and post your first job</p>
        </div>
        <div class="mt-4">
          <vs-button id="post-job" class="pr-6 pl-4 w-full" icon="add" @click.native="postJob()">Post a job</vs-button>
        </div>
      </vs-card>
      <CreateNewJob
        v-if="addNewJob"
        :is-sidebar-active="addNewJob"
        :job-post="selected ? selected : job"
        :edit="editCheck"
        :before-edit="beforeEdit"
        @close-job-creation="handleJob"
      />
    </transition>
    <vs-popup title="Are you sure you want to submit this job for review?" :active.sync="submitReviewPopup">
      <div class="vx-row mr-5 ml-0">
        <vs-button
          :disabled="submittingForReview ? true : false"
          color="primary"
          class="vx-col lg:w-1/4 relative"
          @click="submitForReview(selected)"
          >{{ submittingForReview ? 'Submitting...' : 'Yes' }}</vs-button
        >
        <vs-button
          :disabled="submittingForReview ? true : false"
          color="grey"
          class="vx-col lg:w-1/4 ml-3 relative"
          @click="submitReviewPopup = false"
          >Cancel</vs-button
        >
      </div>
    </vs-popup>

    <JobFeedback
      v-if="selected"
      :mode="archiveOrFilled"
      :active.sync="archiveJobPopup"
      :job="selected"
      @close="(val) => (archiveJobPopup = val)"
      @submit="(feedback) => archiveJob(selected, feedback)"
    />
    <!-- <vs-popup class="overflow-visible" :title="popupTitle" :active.sync="archiveJobPopup">
      <div>
        <p>{{ popupIntro }}</p>
        <br />
        <p
          >Doing so will remove it from the Jobs Board and guides will be unable to apply for the position. The job will be moved into the
          Archived Jobs tab, where you can reactive it again at a later date. Activating the job again will cost you a relevant Job Post
          Credit.
        </p>
        <br />
        <p>You can permanently delete a job once it has been archived.</p>
        <br />
        <p class="mb-5 sm:mb-3"
          ><strong>To help us improve the quality of our service, please provide details on how this position was filled. </strong></p
        >
        <div v-if="filters.archive_options">
          <vs-select v-model="archiveJobReason" class="w-full sm:mb-0 mb-5 overflow-hidden" placeholder="Select a reason">
            <vs-select-item v-for="(item, index) in filters.archive_options['archive_options']" :key="index" :value="item" :text="item" />
          </vs-select>
        </div>
        <div class="mt-3 sm:mt-5">
          <vs-textarea
            v-if="filledReason === 'Other' || archivedReason === 'Other'"
            v-model="otherExplanation"
            class="w-full"
            placeholder="Why did you select other?"
          ></vs-textarea>
        </div>
      </div>
      <div class="vx-row mr-0 sm:mr-5 ml-0 mt-8">
        <vs-button icon="archive" color="primary" icon-before class="vx-col w-full sm:w-1/3 relative" @click="archiveJob(selected)"
          >Submit & Archive Job</vs-button
        >
      </div>
    </vs-popup> -->
  </div>
</template>

<script>
import CreateNewJob from '../components/job-components/CreateNewJob.vue';
import themeConfig from '@/../themeConfig.js';
import JobsTour from '../components/tours/JobsTour.vue';
import JobFeedback from '../components/job-components/JobFeedback.vue';

export default {
  components: {
    CreateNewJob,
    JobsTour,
    JobFeedback,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['sidebar'],
  data() {
    return {
      popupTitle: '',
      popupIntro: '',
      archiveOrFilled: '',
      employer: this.$store.state.employerModule.data,
      filledReason: '',
      routerTransition: themeConfig.routerTransition || 'none',
      selected: null,
      select: false,
      beforeEdit: null,
      itemsPerPage: 4,
      isMounted: false,
      addNewJob: false,
      editCheck: false,
      submittingForReview: false,
      submitReviewPopup: false,
      archiveJobPopup: false,
      feedbackMode: '',
      job: {},
      jobTemplate: {
        expired: false,
        positionFilled: false,
        active: true,
        companyId: '',
        startDate: null,
        endDate: null,
        jobImage: '',
        jobBanner: '',
        compName: '',
        employerId: this.$store.state.employerModule.data.employerId,
        jobTitle: '',
        jobDescr: '',
        jobType: '',
        payTerm: '',
        location: '',
        ndt: '',
        guideType: '',
        secondaryGuideType: '',
        requiredQual: [],
        skillsAndInterests: [],
        jobDuration: '',
        jobDurationType: '',
        averageWorkHours: '',
        languages: [],
        experience: '',
        validFirstAid: '',
        guideCategory: '',
        candidateRequirements: '',
        driversRequired: null,
        requiredSkills: '',
        visaRequirements: '',
        nationalities: [],
        validPDP: '',
        minSalary: '',
        maxSalary: '',
        currency: '',
        itinerary: '',
        cities: '',
        nqfLevels: '',
        views: 0,
        planType: '',
        created_at: new Date(),
        updated_at: new Date(),
        expire_at: new Date(),
        invites: 20,
        id: '',
      },
    };
  },
  computed: {
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
    jobs() {
      this.$store.dispatch('jobsModule/setJobList');
      let jobs = this.formatData(this.$store.state.jobsModule.data);
      jobs.sort((x, y) => {
        let timeX = x.expire_at;
        let timeY = y.expire_at;
        return timeY - timeX;
      });
      return jobs;
    },
  },

  destroyed: function () {
    document.removeEventListener('keyup', this.myMethod);
  },
  created() {
    document.addEventListener('keyup', this.myMethod);
    // this.refreshData();
  },

  methods: {
    inviteGuide(job) {
      console.log(job);
      this.$router.push(
        `/pages/resume?jobId=${job.jobId}&guideType=${job.guideType}&jobType=${job.jobType}&secondaryGuideType=${job.secondaryGuideType}`,
      );
    },
    initSubmitForReview(job) {
      this.selected = job;
      this.submitReviewPopup = true;
    },

    async submitForReview(job) {
      let jobData = job;
      jobData.submittedForReview = true;
      this.submittingForReview = true;
      await this.$store.dispatch('forReviewModule/set', { id: jobData.jobId, employerId: jobData.employerId, jobTitle: jobData.jobTitle });
      await this.$store.dispatch('jobsModule/set', jobData);
      this.submittingForReview = false;
      this.submitReviewPopup = false;
      this.$store.dispatch('toggleGeneralPopup', {
        active: true,
        title: 'Review submitted',
        text: 'Thank you, your job has been successfully submitted for review. You will receive a notification once we have take the necessary action.',
        buttonText: 'Okay',
        click: () => {
          this.$store.dispatch('toggleGeneralPopup', { active: false });
        },
      });
    },

    initArchive(job, type) {
      this.selected = job;
      this.archiveOrFilled = type;
      this.archiveJobPopup = true;
    },
    async archiveJob(jobData, feedback) {
      this.$vs.loading({ type: 'corners' });
      if (this.archiveOrFilled === 'filled') {
        this.selected.filledReason = feedback.archiveReason;
        this.selected.archivedReason = '';
        this.selected.positionFilled = true;
      }
      if (this.archiveOrFilled === 'archive') {
        this.selected.archivedReason = feedback.archiveReason;
        this.selected.filledReason = '';
      }
      this.selected.otherExplanation = feedback.otherExplanation;
      this.selected.isArchived = true;
      this.selected.updated_at = new Date();
      // this.selected.positionFilled = feedback.candidateFound;

      await this.$store.dispatch('archivedJobsModule/set', jobData);
      await this.deleteJob(jobData);
      this.archiveJobPopup = false;
      this.$vs.loading.close();
      this.$vs.notify({
        title: 'Success!',
        text: 'Your job has been successfully archived. You can find it in the Archived Jobs tab.',
        color: 'success',
      });
    },
    async deleteJob(selected) {
      this.jobs.splice(this.jobs.indexOf(selected), 1);
      await this.$store.dispatch('jobsModule/delete', selected.jobId);
      this.deleteJobPopup = false;
      this.selected = this.job;
      this.beforeEdit = null;
    },
    myMethod(event) {
      if (event.keyCode === 27) {
        this.addNewJob = false;
      }
    },

    editJob(job) {
      this.selected = job;
      this.beforeEdit = JSON.parse(JSON.stringify(job));
      if (this.selected === null) {
        this.$vs.notify({
          time: 10000,
          title: 'No Job selected',
          text: 'Please select a job you would like to edit.',
          color: 'warning',
        });
      } else {
        this.editCheck = true;
        this.addNewJob = true;
      }
    },
    postJob() {
      this.job = { ...this.jobTemplate };
      if (!this.$store.getters['companiesModule/getComp'] || !this.$store.getters['employerModule/billingDetailsCheck']) {
        this.$store.dispatch('toggleGeneralPopup', {
          active: true,
          title: 'Your profile is incomplete',
          text: 'Please fill in your Company Information and Billing Details before posting a new job.',
          buttonText: 'Go to Profile',
          click: () => {
            this.$router.push('/pages/profile');
            this.$store.dispatch('toggleGeneralPopup', { active: false });
          },
        });
      } else if (this.employer.freelanceJobPosts === 0 && this.employer.permanentJobPosts === 0) {
        this.$store.dispatch('togglePurchaseCredits', {
          active: true,
          creditType: '',
          title: 'Purchase Job Credits',
          text: 'You need to purchase Job Credits to post a job. All jobs posts are active for 30 days. Credits never expire.',
        });
      } else {
        this.addNewJob = true;
        this.select = false;
      }
    },
    handleJob(job) {
      if (job) {
        this.addNewJob = false;
        this.editCheck = false;
        this.beforeEdit = null;
        this.selected = null;
      } else {
        this.job = { ...this.jobTemplate };
        this.addNewJob = false;
        this.editCheck = false;
        this.select = false;
        this.selected = null;
        this.beforeEdit = null;
      }
    },

    formatData(data) {
      let formattedData = Object.values(data).map(function (key) {
        const fields = key;
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] = fields[key];
        }
        return obj;
      });
      this.$vs.loading.close();
      return formattedData;
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: inherit;
}
.con-vs-dropdown--menu {
  min-width: 140px !important;
}
.vx-card__body {
  @apply relative;
}
</style>
